/** @jsx jsx */
import { jsx, Themed, Button, Link } from "theme-ui";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import Seo from "../components/Seo";
import Headroom from "react-headroom";

export default function Layout(props) {
  return (
    <React.Fragment>
      <Seo {...props} {...props.pageContext.frontmatter} />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Headroom>
          <header
            sx={{
              width: "100%",
              maxWidth: "container",
              mx: "auto",
              px: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bg: "background",
            }}
          >
            <Themed.h4>
              <Link as={GatsbyLink} to="/">
                L'atelier de français
              </Link>
            </Themed.h4>
          </header>
        </Headroom>

        <main
          sx={{
            width: "100%",
            maxWidth: "container",
            mx: "auto",
            p: 3,
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {props.children}
          <GatsbyLink
            to="/message"
            sx={{
              position: "fixed",
              bottom: 0,
              right: 0,
              backgroundColor: "#25d366",
              borderRadius: 9999,
              width: "4.8rem",
              height: "4.8rem",
              padding: "0.72rem",
              margin: "1.2rem",
            }}
          >
            <img src="/wa.svg" alt="WhatsApp" width="100%" />
          </GatsbyLink>
        </main>
        <footer
          sx={{
            width: "100%",
            maxWidth: "container",
            mx: "auto",
            p: 3,
            textAlign: "center",
          }}
        >
          <span role="img" aria-label="copyright">
            ©️
          </span>{" "}
          {new Date().getFullYear()}{" "}
          <span role="img" aria-label="coded">
            💻
          </span>{" "}
          con{" "}
          <span role="img" aria-label="fire">
            🔥
          </span>{" "}
          por
          {` `}
          <Themed.a as={GatsbyLink} to="/">
            mi
          </Themed.a>
          .
        </footer>
      </div>
    </React.Fragment>
  );
}
